import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";


const Section2Col = ({
    id,
    title = "laila schreibt",
    children,
    imagePath,
    gatsbyImageData = undefined,
    className = "content-grid grid-cols-2 max-h-screen xl:min-h-[50vh]",
    imgStyle = "h-full w-full max-h-screen",
    imgBgColor = "",
    imgWrapper = "relative flex w-full"
}) => {
    return (
        <div id={id} key={id} className={className}>
            <div className="content-slim flex flex-col justify-center bg-white">
                <h2 className="block text-left py-4 text-5xl  mb-8 font-heading">{title}</h2>
                <div className="flex flex-col gap-4 text-xl hyphens-auto text-justify leading-relaxed justify-center items-center">
                    {children}
                </div>
            </div>

            <div className={`${imgWrapper} bg-${imgBgColor}`}>
                {!gatsbyImageData &&
                    <img className={imgStyle} src={imagePath} />
                }
                {gatsbyImageData &&
                    <GatsbyImage
                        imgClassName={imgStyle}
                        itemProp="image"
                        image={gatsbyImageData}
                        alt={title}
                    />
                }
            </div>
        </div>
    )
}

export const Section2Left = ({
    id,
    title,
    children,
    imagePath,
    imgBgColor = "",
    gatsbyImageData = undefined,
    imgStyle = "h-full w-full",
    // className = "relative grid md:grid-cols-2 snap-start max-w-screen lg:min-h-screen"
    className = "relative grid md:grid-cols-2 snap-start max-w-screen xl:min-h-[50vh]"
}) => {
    return (
        <div id={id} key={id} className={className}>
            <div className={`relative w-full  bg-${imgBgColor}`}>
                {!gatsbyImageData &&
                    <img className={imgStyle} src={imagePath} />
                }
                {gatsbyImageData &&
                    <GatsbyImage
                        className={imgStyle}
                        imgClassName={imgStyle}
                        itemProp="image"
                        image={gatsbyImageData}
                        alt={title}
                    />
                }            </div>
            <div className="relative flex w-full items-center bg-white">
                <div className="mx-auto px-24">
                    <h2 className="py-4 text-5xl  mb-8 font-heading">{title}</h2>
                    <div className="flex flex-col gap-4 text-xl hyphens-auto text-justify leading-relaxed">
                        {children}
                    </div>
                </div>
            </div>
        </div>
    )
}


export const SectionOffer = ({
    id,
    title,
    children,
    imagePath
}) => {
    return (
        <div id={id} key={id} className="relative flex max-w-screen snap-start flex-row">
            <div className="relative w-full basis-1/2">
                <img className="h-full w-full object-cover" src={imagePath} />
            </div>
            <div className="relative flex w-full basis-1/2 items-center bg-white">
                <div className="mx-auto px-24">
                    <h2 className="py-4 text-5xl  mb-8 font-heading">{title}</h2>
                    <div className="flex flex-col gap-4 text-xl hyphens-auto text-justify leading-relaxed">
                        {children}
                    </div>
                </div>
            </div>
        </div>
    )
}


export default Section2Col;