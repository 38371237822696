import '../App.css';
import React from "react";
import { useRef } from "react";

import Seo from '../components/seo';
import Section2Col, { Section2Left } from '../components/Section2Col';
import TwoColCTA from '../components/TwoColCTA';


import bild from "../images/laila-schreibt-bild.svg";
import blog from "../images/laila-schreibt-blog.svg";
import insta from "../images/laila-schreibt-instagram-bild-vorrat.svg";
import socialmedia from "../images/laila-schreibt-social-media.svg";
import text from "../images/laila-schreibt-text.svg";
import video from "../images/laila-schreibt-video.svg";


// https://play.tailwindcss.com/lQk1tAEWet

const Section = Section2Col


const IndexPage = () => {

    const ref = useRef(null);

    return (
        <div>
            <Seo title='Angebot - Laila schreibt' />
            <div className="wrapper__section">

                <div className='bg-themeBeige'></div>
                <div className='bg-themeLighterBlue'></div>
                <div className='bg-themeLightBrown'></div>
                <div className='bg-themeLightBlue'></div>
                <div className='bg-themeBlue'></div>
                <div className='bg-themeMittelOrange'></div>

                <Section
                    id="section-1"
                    title="Text"
                    imagePath={text}
                    imgBgColor="themeBeige"
                >
                    <p className='prose font-light'>
                        Der Name suggerierts bereits: Laila schreibt. Und was? SEO-optimierte Texte für Websites, witzige Blog-Geschichten, knackige Publireportagen, pointierte Texte für Newsletter, Magazine, Geschäftsberichte oder Kundenmagazine. Und manchmal auch Bücher.
                    </p>
                </Section>

                <Section2Left
                    title="Bild"
                    imagePath={bild}
                    id="secion-bild"
                    imgBgColor="themeMittelOrange"
                >
                    <p className='prose font-light'>
                        Manchmal reichen geschriebene Wörter nicht aus, um die Geschichte in all ihren Facetten zu erzählen. Für Fotoreportagen düse ich in der Weltgeschichte umher, an Events spiele ich die diskrete Paparazza und Portraitbilder werden unkompliziert dort geknippst, wo sich der/die Fotografierende am wohlsten fühlt. Und auch Food findet immer wieder den Weg vor meine Linse (und dann in meinen Mund).
                    </p>
                </Section2Left>


                <Section
                    id="section-2"
                    title="Video"
                    imagePath={video}
                    imgBgColor="themeLightBrown"
                >
                    <p className='prose font-light'>
                        Bewegtbild zieht einfach mehr Aufmerksamkeit auf sich als ein Foto. Video ist aber nicht gleich Video. Für Social Media muss es im Hochformat produziert, sowie kurz und knackig geschnitten sein. Was für ein Reel funktioniert, muss nicht unbedingt auch für einen Imagefilm oder Aftermovie funktionieren.
                    </p>
                </Section>


                <Section2Left
                    title="Social Media"
                    imagePath={socialmedia}
                    id="secion-bild"
                    imgBgColor="themeLightBlue"
                >
                    <p className='prose font-light'>
                        Instagram-Posts, Reels, Social Ads, Stories, Community Management und ist eigentlich noch irgend eine Menschenseele auf Facebook? Social Media kann einem schon mal Kopfzerbrechen bereiten. Darum unterstütze ich dich von der Erstellung eines Social-Media-Konzeptes und Content-Redaktionsplanes bis hin zum Posten auf deinen Kanälen.                    </p>
                </Section2Left>

                <div
                    className='relative mx-8 my-36 lg:py-12 items-center'
                >
                    <h2
                        className='block text-6xl mx-auto text-center'
                    >
                        Laila empfiehlt
                    </h2>
                    <div className='max-w-prose mx-auto'>
                        <p className="prose mt-8 text-xl text-center prose font-light">
                            Zwei Packages für extra viel Fun. Mit einem Blogbeitrag erhält deine Geschichte die Glaubwürdigkeit und Frische, die sie verdient. Oder wie wär’s mit einem Vorrat an schmucken Bildern für deinen Instagram-Account? Ich bin ganz Ohr.                        </p>
                    </div>

                    <div
                        className='relative grid grid-cols-2 my-24 gap-24 max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl'
                    >
                        <div className='group relative w-full cursor-pointer'>
                            <div
                                className='block bg-blue-green w-full overflow-hidden text-xl hyphens-auto text-justify leading-relaxed' 
                            >
                                <img
                                    src={blog}
                                    className="w-full h-auto object-cover transition-opacity duration-700 opacity-100 ease-in hover:opacity-80"
                                    alt=""
                                />
                            </div>

                            <h2 class="py-4 text-5xl mt-4 font-heading">Blog</h2>
                            <p class="prose text-xl hyphens-auto text-left prose font-light">
                                Meine Spezialität: Blog-Geschichten ohne Blabla, Tamtam, fadengrad ins Gesicht, mit wenig Gedöns, viel Herzblut und stet einer Prise Selbstironie. Du willst auch so einen Blog-Beitrag?
                            </p>
                        </div>

                        <div className='group relative w-full cursor-pointer '>
                            <div
                                className='block w-full overflow-hidden text-xl hyphens-auto text-justify leading-relaxed'
                            >
                                <img
                                    src={insta}
                                    className="w-full h-auto object-cover transition-opacity duration-700 opacity-100 ease-in hover:opacity-80"
                                    alt=""
                                />
                            </div>
                            <h2 class="py-4 text-5xl mt-4 font-heading">Instagram Bild-Vorrat</h2>
                            <p class="prose text-xl hyphens-auto text-left prose font-light">
                                Dein Insta-Kanal vegetiert vor sich hin, du hast aber weder Ideen, Zeit noch mentale Kapazität für die Content-Produktion? Ich erstelle dir einen Grundstock an passenden Bildern.
                            </p>
                        </div>

                    </div>

                </div>



                <TwoColCTA />





            </div>

        </div >
    )
}

export default IndexPage;
