import React from "react";


export default function TwoColCTA({
    title = "Zeit für deine Story.",
    text = "Egal ob Schnapsidee oder pfannenfertiges Konzept. Lass uns gemeinsam dein Projekt ins Rollen bringen.",
    btnLabel = "Hallo sagen.",
    className = "cta snap-start relative py-24 lg:py-48 bg-themeLila"
}) {

    return (
        <div className={className}>

            <div className='relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl grid md:grid-cols-2 gap-24'>

                <div className='group relative w-full cursor-pointer'>
                    <h2
                        className='text-5xl md:text-8xl align-text-top'
                    >
                        {title}
                    </h2>
                </div>
                <div className='group relative w-full cursor-pointer'>
                    <p
                        className='text-3xl md:text-4xl mb-12 align-text-top'
                    >
                        {text}
                    </p>
                    <button
                        className='whitespace-nowrap inline-flex items-center justify-center px-4 py-2 transition-all font-heading text-base font-medium text-black border-2 border-black bg-transparent hover:bg-themeHover hover:text-white hover:border-themeHover'
                    >
                        {btnLabel}
                    </button>
                </div>

            </div>

        </div>
    )
}